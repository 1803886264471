// Entry point for the build script in your package.json
import "./controllers"
import "./src/clipboard"
import "./src/turbo_native/bridge"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()


document.addEventListener("turbo:load", function(event) {
    window.dataLayer = window.dataLayer || []
    function gtag(){dataLayer.push(arguments)}
    gtag('js', new Date())
    gtag('config', 'G-2HV2W6K36Q', {'page_location': event.detail.url})
  }, false)



